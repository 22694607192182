import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 mb-3" }
const _hoisted_3 = { class: "card pt-4 mb-xl-9" }
const _hoisted_4 = { class: "card-header border-0" }
const _hoisted_5 = { class: "card-title" }
const _hoisted_6 = { class: "fw-bolder" }
const _hoisted_7 = { class: "card-body pt-0" }
const _hoisted_8 = { class: "fw-bolder fs-2" }
const _hoisted_9 = { class: "fs-7 fw-normal text-muted" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-12" }
const _hoisted_12 = { class: "card pt-4 mb-xl-9" }
const _hoisted_13 = { class: "card-body" }
const _hoisted_14 = { class: "p-5 mb-5 bg-primary bg-opacity-25 rounded" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "col" }
const _hoisted_17 = ["placeholder"]
const _hoisted_18 = { class: "col" }
const _hoisted_19 = ["placeholder"]
const _hoisted_20 = { class: "col" }
const _hoisted_21 = {
  class: "table-responsive",
  id: "printableTable"
}
const _hoisted_22 = { class: "table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3" }
const _hoisted_23 = { class: "fw-bolder text-muted" }
const _hoisted_24 = { class: "min-w-120px" }
const _hoisted_25 = { class: "min-w-120px" }
const _hoisted_26 = { class: "min-w-120px text-center" }
const _hoisted_27 = { key: 0 }
const _hoisted_28 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary fs-6"
}
const _hoisted_29 = { class: "text-muted fw-bold text-muted d-block fs-7" }
const _hoisted_30 = { class: "text-center" }
const _hoisted_31 = ["onClick"]
const _hoisted_32 = { key: 0 }
const _hoisted_33 = { key: 1 }
const _hoisted_34 = { class: "d-flex row-auto flex-center w-100 h-50px" }
const _hoisted_35 = { class: "text-muted fs-6 fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t("pages.roadmap.availableRoadmaps")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("pages.roadmap.descBis")), 1)
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters.identity) = $event)),
                    type: "text",
                    name: "fname",
                    class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                    placeholder: _ctx.$t('pages.users.name'),
                    onKeyup: _cache[1] || (_cache[1] = ($event: any) => {
                                        _ctx.filters.identity.length >= 2
                                            ? _ctx.debounce(() => {
                                                  _ctx.getRelatedRoadMaps();
                                              })
                                            : null
                                    })
                  }, null, 40, _hoisted_17), [
                    [_vModelText, _ctx.filters.identity]
                  ])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters.perimeter) = $event)),
                    type: "text",
                    name: "fname",
                    class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                    placeholder: _ctx.$t('pages.usersList.perimeter'),
                    onKeyup: _cache[3] || (_cache[3] = ($event: any) => {
                                        _ctx.filters.perimeter.length >= 2
                                            ? _ctx.debounce(() => {
                                                  _ctx.getRelatedRoadMaps();
                                              })
                                            : null
                                    })
                  }, null, 40, _hoisted_19), [
                    [_vModelText, _ctx.filters.perimeter]
                  ])
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("button", {
                    type: "submit",
                    class: "ms-3 btn btn-icon btn-light btn-active-light-primary",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.resetFilter()))
                  }, _cache[5] || (_cache[5] = [
                    _createElementVNode("i", { class: "fas fa-redo fs-7 pt-1" }, null, -1)
                  ]))
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("table", _hoisted_22, [
                _createElementVNode("thead", null, [
                  _createElementVNode("tr", _hoisted_23, [
                    _createElementVNode("th", _hoisted_24, _toDisplayString(_ctx.$t("pages.users.name")), 1),
                    _createElementVNode("th", _hoisted_25, _toDisplayString(_ctx.$t("pages.usersList.perimeter")), 1),
                    _createElementVNode("th", _hoisted_26, _toDisplayString(_ctx.$t("pages.roadmap.titlePlural")), 1)
                  ])
                ]),
                (!_ctx.loading)
                  ? (_openBlock(), _createElementBlock("tbody", _hoisted_27, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (user) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          key: user.id
                        }, [
                          _createElementVNode("td", null, [
                            _createElementVNode("a", _hoisted_28, _toDisplayString(user.userprofile.firstname), 1),
                            _createElementVNode("span", _hoisted_29, _toDisplayString(user.userprofile.lastname), 1)
                          ]),
                          _createElementVNode("td", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(user.userprofile.related_companies, (company) => {
                              return (_openBlock(), _createElementBlock("span", {
                                key: company.pk,
                                class: "badge-light badge text-primary me-2"
                              }, [
                                _cache[6] || (_cache[6] = _createElementVNode("i", { class: "fas fa-sitemap fs-5 me-2" }, null, -1)),
                                _createTextVNode(" " + _toDisplayString(company.name), 1)
                              ]))
                            }), 128))
                          ]),
                          _createElementVNode("td", _hoisted_30, [
                            _createElementVNode("a", {
                              class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1",
                              onClick: ($event: any) => (this.userRoadmap(user.id))
                            }, _cache[7] || (_cache[7] = [
                              _createStaticVNode("<span class=\"svg-icon svg-icon-muted svg-icon-3\"><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"15\" viewBox=\"0 0 16 15\" fill=\"none\"><rect y=\"6\" width=\"16\" height=\"3\" rx=\"1.5\" fill=\"black\"></rect><rect opacity=\"0.3\" y=\"12\" width=\"8\" height=\"3\" rx=\"1.5\" fill=\"black\"></rect><rect opacity=\"0.3\" width=\"12\" height=\"3\" rx=\"1.5\" fill=\"black\"></rect></svg></span>", 1)
                            ]), 8, _hoisted_31)
                          ])
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_32, _cache[8] || (_cache[8] = [
                    _createElementVNode("div", { class: "d-flex row-auto flex-center w-100 h-200px" }, [
                      _createElementVNode("span", {
                        class: "spinner-border text-primary",
                        role: "status"
                      })
                    ], -1)
                  ])))
                : _createCommentVNode("", true),
              (_ctx.noResult)
                ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                    _createElementVNode("div", _hoisted_34, [
                      _createElementVNode("span", _hoisted_35, _toDisplayString(_ctx.$t("general.noResult")), 1)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ])
    ])
  ], 64))
}